<template>
  <div class="atcSign">
    <h2>
      <i class="iconfont icon-fanhui" @click="back"></i>教学活动 > 活动管理 >
      随堂测验
    </h2>
    <ul class="ul">
      <li>
        <span class="marks">随堂<br />测验</span>
        <div>
          {{actInfo.title }}<br />
          <em>{{ actInfo.str_time | formatimes("yyyy-MM-dd hh:mm") }}</em
          ><span v-if="actInfo.left_time"
            ><i class="iconfont icon-time"></i>{{ actInfo.left_time }}</span
          >
        </div>
      </li>
    </ul>
    <div class="questionsSign">
      <main>
        <div class="mainWrap">
          <div class="questionstishi">
            <div v-for="(item, index) in question_infos" :key="index">
              <Radio
                class="itemWrap"
                v-if=" item.retype == 1"
                :list="item"
              ></Radio>
              <!-- 多选 -->
              <Checkbox
                class="itemWrap"
                v-if="
                   item.retype == 2 || item.retype == 3
                "
                :list="item"
              ></Checkbox>
              <!-- 判断 -->
              <Estimate
                class="itemWrap"
                v-if=" item.retype == 5"
                :list="item"

              ></Estimate>
              <!-- 论述 -->
              <Discuss
                class="itemWrap"
                v-if=" item.retype == 4"
                :list="item"
              ></Discuss>
            </div>
          </div>
        </div>
      </main>
    </div>
    <el-button
      type="primary"
      style="margin: 0 auto; display: block"
      :disabled="subFlag"
      @click="actSub"
      >点击提交</el-button
    >
    <!--        <el-button type="info" style="margin: 0 auto; display: block">已结束</el-button>-->
    <el-dialog
      custom-class="dial"
      title="提示"
      :visible.sync="centerDialogVisible"
      width="30%"
    >
      <div style="text-align: center">
        <p style="padding-bottom: 28px">{{ dialogtitle }}</p>
        <!-- 返回 -->
        <span slot="footer" class="dialog-footer" v-if="type == 1">
          <el-button @click="centerDialogVisible = false" type="info"
            >取 消</el-button
          >
          <el-button type="primary" @click="$parent.sign = true"
            >确 定</el-button
          >
        </span>
        <!-- 提交 -->
        <span slot="footer" class="dialog-footer" v-if="type == 2">
          <el-button @click="centerDialogVisible = false" type="info"
            >取 消</el-button
          >
          <el-button type="primary" @click="realSub">确 定</el-button>
        </span>
        <!-- 未作答完成 -->
        <span slot="footer" class="dialog-footer" v-if="type == 3">
          <el-button @click="centerDialogVisible = false" type="info"
            >继续作答</el-button
          >
          <el-button type="primary" @click="realSub">确定提交</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { sample } from "../../pratice/pape_do/assets/api";
import { paperInfo, paperSubmit } from "../assets/api";
import Checkbox from "./coms/Checkbox"; // 多选
import Radio from "./coms/Radio"; // 单选
import Estimate from "./coms/Estimate"; // 判断
import Discuss from "./coms/Discuss"; // 论述

export default {
  name: "classQuiz",
  components: { Checkbox, Radio, Estimate, Discuss },
  data() {
    return {
      actInfo: {},
      actId: "",
      title: "",
      left_time: "剩余10分钟",
      str_time: "1766666666",
      centerDialogVisible: false,
      dialogtitle: "",
      type: 1,
      //
      question_infos: [],
      questionData: {},
      num: "",
      seconds: 0,
      upTime: "",
      downTime: "",
      iscollect: "",
      bfIshow: true,
      answerTotal: [],
      area_type: "",
      newtime: "",
      endtime: "",
      Question_list: [],
      extar: {},
      Parper: "",
      papermainObj: {},
      collect_id: "",
      subFlag: false,
    };
  },
  mounted() {
    // this.getInfo();
    // this.getList();
  },
  methods: {
    getInfo(id) {
      this.actId = id;
      const params = {
        id: id,
        course_id: this.$route.query.course_id,
        cst_id: this.$route.query.cst_id,
      };
      paperInfo(params).then((res) => {
        if (res.code == 0) {
          this.actInfo = res.data.paper_show;
          if (res.data.paper_show.question_show_list.length > 0) {
            let b = [];
            // this.newtime = res.data.paper_show.Newtime;
            // // this.endtime = res.data.Paper.TeachingActivities.end_time;
            // // this.timer = setInterval(this.endTimer, 1000);
            // // this.extar = res.data.Extar;
            // this.Parper = res.data.paper_show;
            this.Question_list = res.data.paper_show.question_show_list
            this.dataProcessing(
              res.data.paper_show.question_show_list,
              b,
              // res.data.Stuffinfos,
              // res.data.Sectioninfos
            );
          } else {
            this.$message.error("暂无试题");
          }
        }
      });
    },
    endTimer() {
      this.newtime++;
      if (this.newtime >= this.endtime) {
        this.subFlag = true;
        clearTimeout(this.timer);
      }
    },
    back() {
      this.centerDialogVisible = true;
      this.dialogtitle = "返回将退出当前页面并且不会保存答题进度，确定退出？";
      this.type = 1;
    },
    actSub() {
      let a = this.Question_list.every((item) => {
        return (
          ((item.retype == 1 || item.retype == 5 || item.retype == 4) &&
            item.radio == "" &&
            item.is_must_answer == 1) ||
          ((item.retype == 2 || item.retype == 3) &&
            item.radio.length == 0 &&
            item.is_must_answer == 1)
        );
      });
      if (a) {
        this.$message.error("你有必答题未答");
      } else {
        let b = this.Question_list.every((item) => {
          return (
            ((item.retype == 1 || item.retype == 5 || item.retype == 4) &&
              item.radio) ||
            ((item.retype == 2 || item.retype == 3) && item.radio.length > 0)
          );
        });
        if (b) {
          this.centerDialogVisible = true;
          this.dialogtitle = "确定提交作答？";
          this.type = 2;
        } else {
          this.centerDialogVisible = true;
          this.dialogtitle = "有未作答的题目，确定提交作答？";
          this.type = 3;
        }
      }
    },
    realSub() {
      let answer_list = [];
      this.Question_list.forEach((item) => {
        if (item.retype == 1 || item.retype == 5) {
          let obj = {
            question_id: item.question_id,
            answer: item.radio,
            answer_image: "",
          };
          answer_list.push(obj);
        }
        if (item.retype == 2 || item.retype == 3) {
          let obj = {
            question_id: item.question_id,
            answer: item.radio,
            answer_image: "",
          };
          answer_list.push(obj);
        }
        if (item.retype == 4) {
          let arr = [];
          if (item.user_pic.length) {
            arr = item.user_pic.map((i) => i.path);
          }
          let arr1 = [];
          arr.map((item) => {
            if (item != undefined) {
              arr1.push(item);
            }
          });
          let obj = {
            question_id: item.question_id,
            answer: item.radio.toString(),
            answer_image: item.user_pic.length > 0 ? arr1.toString() : "",
          };
          answer_list.push(obj);
        }
      });
      const params = {
        cst_id: this.$route.query.cst_id,
        ta_id: this.actId,
        answer_list: JSON.stringify(answer_list),
      };
      paperSubmit(params).then((res) => {
        if (res.code == 0) {
          this.$message.success("提交成功");

          this.$parent.sign = true;
          this.$parent.getList();
        }
      });
    },
    dataProcessing(a, b, c, d) {
      a.map((item) => {
        if (item.retype == 1 || item.retype == 5 || item.retype == 4) {
          this.$set(item, "radio", "");
          if (item.user_answer != "") {
            this.$set(item, "radio", item.user_answer);
          }
        } else if (item.retype == 2 || item.retype == 3) {
          this.$set(item, "radio", []);
          if (item.user_answer != "") {
            var ss = item.user_answer.split(",");
            this.$set(item, "radio", ss);
          }
        }
        // this.Question_list.push(item);
        this.$nextTick(() => {

            b.push(item);

        });
        this.$nextTick(() => {
          if (d != undefined && d.length > 0) {
            d.map((itembf) => {
              this.$set(itembf, "timu", []);
              b.map((item) => {
                if (itembf.section_id == item.section_id) {
                  itembf.timu.push(item);
                }
              });
            });
            this.question_infos = d;
            this.bfIshow = true;
          } else {
            this.question_infos = b;
            this.bfIshow = false;
          }
        });
      });
    },
    unique(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i].is_stuff == undefined) {
            if (arr[i].stuff_id == arr[j].stuff_id) {
              arr.splice(j, 1);
              j--;
            }
          }
        }
      }
      return arr;
    },
    getList() {
      let params = {
        special_id: 15,
        column_id: 18,
        catalogue_id: 45,
        num: 15,
        smodel: 1,
        answer_id: 994,
      };
      sample(params).then((res) => {
        if (res.code == 0) {
          if (res.data.Question_list.length > 0) {
            let b = [];
            this.newtime = res.data.Newtime;
            this.extar = res.data.Extar;
            this.Parper = res.data.Paper;
            this.Question_list = res.data.Question_list;
            this.dataProcessing(
              res.data.Question_list,
              b,
              res.data.Stuffinfos,
              res.data.Sectioninfos
            );
          } else {
            this.$message.error("暂无试题");
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.atcSign {
  height: 100%;
  box-sizing: border-box;
  h2 {
    font-weight: normal;
    font-size: 14px;
    padding-bottom: 16px;
    i {
      margin-right: 10px;
      color: #787d84;
      vertical-align: middle;
    }
  }
  .ul {
    background: #fff;
    padding: 0 30px;
    margin-bottom: 20px;
    border-radius: 3px;
    li {
      height: 75px;
      display: flex;
      padding-top: 16px;
      box-sizing: border-box;
      .marks {
        font-size: 13px;
        color: #fff;
        border-radius: 4px;
        background: #00c292;
        text-align: center;
        width: 42px;
        height: 42px;
        line-height: 16px;
        margin-right: 16px;
        box-sizing: border-box;
        padding-top: 5px;
      }
      div {
        font-size: 14px;
        cursor: pointer;
        flex: 1;
        span {
          font-size: 13px;
          color: #ff7d4e;
        }
        i {
          font-size: 13px;
          margin-right: 5px;
        }
        em {
          color: #666666;
          font-size: 13px;
          font-style: normal;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
