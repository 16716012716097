<template>
  <!-- 多选 -->
  <div class="checkWrap" :id="'question' + list.question_seq">
    <b class="itemNum">{{ list.question_seq }}<span v-if="list.is_must_answer" class="colorRed fontSize20">*</span></b>
    <div class="itemDetail">
      <div class="detailTitle">
        <div class="mb10">
        <span class="detailCate">{{
          list.retype == 2 ? "多选题" : "不定项选择题"
        }}</span>
        </div>
        <div class="detailContent" v-html="list.title"></div>
      </div>
      <div class="detailOption">
        <el-checkbox-group v-model="list.radio">
          <el-checkbox
            :label="item.option_mark"
            v-for="(item, index) in list.question_option"
            :key="index"
          >
            <i class="optionIcon">{{ item.option_mark }}</i>
            <div class="optionContent" v-html="item.option_content"></div>
          </el-checkbox>
        </el-checkbox-group>
      </div>
<!--      <Point :thought_dial="list.thought_dial" v-if="list.thought_dial"></Point>-->
    </div>
  </div>
</template>

<script>


export default {
  props: {
    list: Object,
    is_stuff: Number,
  },
  components: {
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {

  },
};
</script>

<style scoped lang=""></style>
