<template>
  <!-- 论述 -->
  <div class="discussWrap" :id="'question' + list.question_seq">
    <b class="itemNum">{{ list.question_seq }}<span v-if="list.is_must_answer" class="colorRed fontSize20">*</span></b>
    <div class="itemDetail">
      <div class="detailTitle">
        <div class="mb10">
        <span class="detailMate" v-if="is_stuff == 1">资料</span>
        <span class="detailCate">论述题</span>
         </div>
        <div class="detailContent" v-html="list.title"></div>
      </div>
      <div class="detailOption">
        <div class="optionWrap">
          <el-input
            type="textarea"
            :rows="8"
            placeholder="请输入内容"
            v-model="list.radio"
            class="mb20"
          ></el-input>
          <el-upload
            :data="upld"
            action="/api/uploader/v1/upload_file"
            list-type="picture-card"
            :limit="9"
            :file-list="list.user_pic"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="sucesspic"
            :on-exceed="handleExceed"
            :before-upload="beforeUpload"
          >
            <i class="el-icon-plus"></i>
            <br />
            <span>添加图片</span>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  props: {
    list: Object,
    is_stuff: Number,
  },
  components: {},
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      upld: {
        siteid: JSON.parse(window.sessionStorage.getItem("infos")).siteid,
        bucket_type: 2,
        up_config: 1,
        uploadfiletype: 1,
      },
    };
  },
  mounted() {},
  methods: {
    //上传图片
    sucesspic(response) {
      console.log(response);
      this.list.user_pic.push({
        url: response.data.inline_url,
        path: response.data.uuid,
      });
    },
    handleRemove(file, fileList) {
      if (this.list.chang_img) {
        this.list.chang_img.push(file.path);
      } else {
        this.$set(this.list, "chang_img", []);
        this.list.chang_img.push(file.path);
      }
      this.list.user_pic.forEach((item, index) => {
        if (file.url == item.url) {
          this.list.user_pic.splice(index, 1);
        }
      });
    },
    handlePictureCardPreview(file, fileList) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 文件上传的状态
    beforeUpload(file, fileList) {
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      var reg = new RegExp('[\\\\/:*?\"<>|]')
      let errors = reg.test(file.name)
      if(errors){
        this.$message.warning("文件名不能包含“?”、“、”、“╲”、“/”、“*”、““”、“”“、“”、“|”等特殊字符");
        return false;
      }
      var leng = file.name.substr(0,file.name.lastIndexOf(".") ).length
      if(leng>255){
        this.$message.warning("文件名太长");
        return false;
      }
      if(leng==0){
        this.$message.warning("文件名不能为空");
        return false;
      }
      const extension1 = testmsg === "jpg";
      const extension2 = testmsg === "png";
      const extension3 = testmsg === "jpeg";
      const isLimit = file.size / 1024 / 1024;
      if (!extension1 && !extension2 && !extension3) {
        this.$message.warning("您只能上传以下类型文件：.jpg, .png, .jpeg!");
        return false;
      }
      if (extension1 || extension2) {
        if (isLimit > 10) {
          this.$message.warning("文件大小不得超过10M");
          return false;
        }
      }
      return extension1 || extension2 || extension3;
    },
    // 文件上传限制
    handleExceed(files, fileList) {
      this.$message.warning(`最多上传 ${fileList.length} 个文件`);
    },
  },
};
</script>

<style scoped lang=""></style>
