<template>
  <!-- 单选 -->
  <div class="radioWrap" :id="'question' + list.question_seq">
    <b class="itemNum">{{ list.question_seq }} <span v-if="list.is_must_answer" class="colorRed fontSize20">*</span></b>
    <div class="itemDetail">
      <div class="detailTitle">
        <div class="mb10">
          <span class="detailCate">单选题</span>
        </div>
        <div class="detailContent" v-html="list.title"></div>
      </div>
      <div class="detailOption">
        <el-radio-group v-model="list.radio">
          <el-radio
            :label="item.option_mark"
            v-for="(item, index) in list.question_option"
            :key="index"
          >
            <i class="optionIcon">{{ item.option_mark }}</i>
            <div class="optionContent" v-html="item.option_content"></div>
          </el-radio>
        </el-radio-group>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  props: {
    list: Object,
  },
  components: {
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {

  },
};
</script>

<style scoped lang=""></style>
